import React from "react";
import Tabs from "react-responsive-tabs";
import MySlider from "../components/tabsContent";
// IMPORTANT you need to include the default styles
import "react-responsive-tabs/styles.css";
const tabs = [
  {
    title: "One Dashboard",
    content: (
      <MySlider
        title={"Centralized Control Panel"}
        desc={
          "Build and manage multiple sites from a single dashboard. You can also run updates, monitor performance and onboard new prospects all from the same place."
        }
      />
    ),
  },
  {
    title: "Security",
    content: (
      <MySlider
        title={"Robust Site & Server Security"}
        desc={
          "Broad security levels across OS, network and apps. Built into the Plesk hosting control panel core and enhanced through our class-leading security partners."
        }
      />
    ),
  },
  {
    title: "+100 Extensions",
    content: (
      <MySlider
        title={"Expandable with 100+ Extensions"}
        desc={
          "Do more with your platform as you integrate tools like DigiCert SSL, CloudLinux, Cloudflare, Atomicorp, NodeJs, PHP, NGINX, Let’s Encrypt, Git, ImunifyAV, Backup to Cloud Pro and more."
        }
      />
    ),
  },
  {
    title: "Wordpres Toolkits",
    content: (
      <MySlider
        title={"The Ultimate Toolkit for WordPress Sites"}
        desc={
          "Save hours with One power tool to install, manage, update and secure all sites. Includes: automated AI-powered regression testing, cloning, staging environment, backup and restore features."
        }
      />
    ),
  },
  {
    title: "Compatibility",
    content: (
      <MySlider
        title={"Compatible across all platforms and hyperscale options"}
        desc={
          "The only WebOps / hosting platform that runs on all virtualization and container platforms. Plus huge cloud providers like AWS, Google, Alibaba, Azure & DigitalOcean."
        }
      />
    ),
  },
  {
    title: "Customization",
    content: (
      <MySlider
        title={"Complete Flexibility and Control"}
        desc={
          "You get full root access via SSH on every Plesk-powered VPS and dedicated server so you can install any third-party, open source, or customized application you need."
        }
      />
    ),
  },
];

function getTabs() {
  return tabs.map((tab, index) => ({
    title: tab.title,
    getContent: () => tab.content,
    /* Optional parameters */
    key: index,
    tabClassName: "tab",
    panelClassName: "panel",
  }));
}

function Tab() {
  return <Tabs items={getTabs()} transformWidth={768} />;
}
export default Tab;
