import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Accordion from "../components/accordion";
import ImageRight from "../components/imageRight";
import ImageLeft from "../components/imageLeft";
import Landing from "../components/landingSection";
import LI from "../components/plan-li";
import PlanCard from "../components/planSelectCard";
import PricingDiv from "../components/planSelect-li";
import Tabs from "../components/tabs";
import AccordionContent from "../DB/Accordion-encrypt-protection";
// import Packages from "../DB/shared-plan-card";
import Image from "../components/imageProcessing";
import { graphql, useStaticQuery } from "gatsby";

function sharedHosting() {
	const data = useStaticQuery(graphql`
		query MyQueryy {
			allProducts(filter: { productGroupID: { eq: 12 } }) {
				nodes {
					productID
					productGroupID
					name
					description
					monthly
					quarterly
					semiannually
					annually
					biennially
					triennially
				}
			}
		}
	`);
	return (
		<Layout>
			<SEO
				keywords={[
					"shared hosting",
					"what is shared hosting",
					"what is a shared hosting",
					"shared hosting vs vpsHosting",
					"what's shared hosting",
					"shared hosting vs cloud hosting",
					"shared hosting best",
					"shared hosting vs wordpressHosting hosting",
					"shared hosting vs dedicated hosting",
					"shared hosting cheap",
					"shared hosting server",
					"shared hosting free",
					"shared hosting cpanel",
					"shared hosting with cpanel",
					"shared hosting provider",
					"shared hosting for wordpressHosting",
					"shared hosting wordpressHosting",
					"shared hosting website",
					"shared hosting linux",
					"shared hosting or vpsHosting",
					"shared hosting price",
					"shared hosting offers",
					"shared hosting nginx",
					"shared hosting ram",
					"what is shared hosting service",
				]}
				title="Shared Hosting"
				image={"/hostylus-card-preview.jpg"}
				description="Get started with affordable Shared Hosting packages that help take your website to the top with free migration and a 100% guarantee of zero data loss."
			/>
			<Landing
				header="Affordable Shared Hosting"
				desc="A hosting service is a necessity if you’re willing to take over with your online businessHosting. And for you, we pull the ropes together to provide you with quality and price that fit within your budget, so quit stalling and get it over with us!"
				btnURL="#sharedPlans"
				btnText={"get started"}
				image={
					<Image
						image={"shared_kZqs9n_97g.png"}
						alt={
							" A smiling group of people gathered around a laptop & discussing"
						}
					/>
				}
				hideSecondary_btn={"hidden"}
			/>
			<section
				id="sharedPlans"
				className="sharedPlans max-w-5xl mx-auto my-16 mobile:pb-3 tablet:px-8"
			>
				<h2 className="text-3xl text-black font-bold text-center ">
					Upgraded plans today for a better tomorrow
				</h2>
				<h6 className="text-gray-400 text-center mobile:p-4">
					Unleash the technology of your mind, and elevate your businessHosting
					sales with our businessHosting hosting plans
				</h6>

				<div id="results"></div>
				<div className="g grid grid-cols-3 gap-3 flex justify-items-center mt-8 mobile:grid-cols-1 tablet:grid-cols-1 py-8 mobile:py-4">
					{data.allProducts.nodes.map(function (items, index) {
						var stripedHtml = items.description.replace(/<[^>]+>/g, "");
						var description = stripedHtml.split("\n");
						const divInfo = Array.from(
							{ length: description.length / 2 },
							(_, i) => description[2 * i] + "." + description[2 * i + 1]
						); //every tow elements in description compained into one element in divInfo

						return (
							<PlanCard
								key={index}
								price1m={items.monthly}
								price12m={items.annually}
								price24m={items.biennially}
								price36m={items.triennially}
								header={items.name}
								productID={items.productID}
								myItems={
									<>
										{divInfo.map((listItem, listIndex) => (
											<PricingDiv
												key={listIndex}
												header={listItem.split(".")[0]}
												desc={listItem.split(".")[1].trim()}
											/>
										))}
									</>
								}
							/>
						);
					})}
				</div>
			</section>

			<ImageRight
				image={
					<Image
						image={"migration_hub_au0D62X0cB.png"}
						alt={"a blue image explaining what hostylus migration includes"}
					/>
				}
				header={"Migration Hub"}
				smallHeader={"Migration has never been easier"}
				desc={
					"Fear no loss in data or ranking while migrating your website with recovery plans that prevent any imposter from sneak peeking at your data"
				}
				btnLink={"/#sharedPlans"}
				btnText={"MIGRATE YOUR SITE"}
				hideShopNow={"hidden"}
			/>
			<ImageLeft
				image={
					<Image
						image={"solid_linux_d6CJdfO4z.png"}
						alt={"STEADFAST LINUX SERVERS on Hostylus"}
					/>
				}
				header={"Steadfast Linux Servers"}
				desc={
					<ul
						style={{
							listStyleImage:
								"url('https://ik.imagekit.io/softylus/blueTick_nzCxIZ4xA.svg')",
							listStylePosition: "inside",
							textAlign: "left",
						}}
						className="list-disc"
					>
						<LI
							LI={"24/7 support from our hosting experts."}
							textSize={"text-lg"}
						/>
						<LI
							LI={"Application installers including WordPress Hosting"}
							textSize={"text-lg"}
						/>
						<LI LI={"FREE migration to our servers"} textSize={"text-lg"} />
						<LI LI={"Latest PHP 7.0 - 7.4"} textSize={"text-lg"} />
						<LI LI={"Daily backups"} textSize={"text-lg"} />
						<LI LI={"Firewall + Security package"} textSize={"text-lg"} />
					</ul>
				}
				hideShopNow={"hidden"}
				hideLearnMore={"hidden"}
			/>
			<section className="bg-gray-100 py-16">
				<div className="SharedHosting px-32 mobile:px-3 tablet:px-8 laptop:px-1">
					<h2 className="text-center text-black mb-4 text-4xl font-bold">
						Plesk
					</h2>
					<h4 className="text-gray-400 text-center mb-8 text-md px-16 my-3">
						Revealing Plesk next-level server management platform
					</h4>
					<Tabs />
				</div>
			</section>
			<section className="acc py-16 grid justify-items-center">
				<div className=" px-8 max-w-2xl">
					<h2 className=" capitalize text-center text-black mb-16 text-4xl font-bold uppercase">
						Entery-level Let’s encrypt Protection
					</h2>
					{AccordionContent.map((items, index) => (
						<Accordion
							key={index}
							title={items.title}
							content={items.content}
						/>
					))}
				</div>
			</section>
		</Layout>
	);
}
export default sharedHosting;
