import React from "react";
import PropTypes from "prop-types";
export default function tabsContent(props) {
  return (
    <section className="myslider">
      <div className="slide h-64">
        <div className="px-32 mobile:px-6 h-full text-center laptop:px-1">
          <img
            className="w-12 mobile:w-8 my-6 mx-auto"
            src="https://ik.imagekit.io/softylus/blueTick_nzCxIZ4xA.svg"
          />
          <h1 className="pb-4 text-black text-2xl mobile:text-lg">
            {props.title}
          </h1>
          <p className="py-1 text-gray-500 text-lg mobile:text-sm laptop:px-10">
            {props.desc}
          </p>
        </div>
      </div>
    </section>
  );
}
tabsContent.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};
